import { useSearchStore } from '@stores/search.store'
import { useUserStore } from '@stores/user.store'

import { useCallback } from 'react'

import { useRouter } from 'next/navigation'

import { JobSearch } from '@types'

import { useShallow } from 'zustand/react/shallow'

import { getSerpQueryString } from '@lib/helpers/client'

import useLocale from './client/useLocale'
import useIsLoggedIn from './user/useIsLoggedIn'

import useUpdatePreferences from './user/useUpdatePreferences'

/**
 * Submits a search
 * @param method {string} - Next.js router navigation method
 * @param params { Partial<JobSearch> } - additional search parameters, overrides the stored search data
 */
export default function useSearchDispatcher(): (params?: Partial<JobSearch>) => void {
  const [nextSearch, setSearch, setNextSearch] = useSearchStore(
    useShallow((state) => [state.nextSearch, state.setSearch, state.setNextSearch]),
  )
  const [profile, setProfile] = useUserStore(useShallow((st) => [st.profile, st.setProfile]))
  const { isLoggedIn } = useIsLoggedIn()
  const updateSearchPreferences = useUpdatePreferences()
  const router = useRouter()
  const country = useLocale()

  return useCallback(
    (params) => {
      const search: JobSearch = { ...nextSearch, ...params }
      const url = `/${country}/search?${getSerpQueryString(search)}`

      if (isLoggedIn) {
        const p = { ...profile }
        if (p.user) {
          p.user.maxRadius = search.maxRadius
          setProfile(p)
        }
      }

      setSearch(search)
      setNextSearch(search)

      updateSearchPreferences(undefined, {
        query: search.job.micro || '',
        location_name: search.location.name || '',
        location_state: search.location.state || '',
        location_county: search.location.county || '',
        location_id: search.location.id || '',
        location_long: String(search.location.location?.[0]) || '',
        location_lat: String(search.location.location?.[1]) || '',
      })
      router.push(url)
    },
    [nextSearch, setSearch, setNextSearch, isLoggedIn, profile, setProfile, router, updateSearchPreferences, country],
  )
}
