import { useSearchStore } from '@stores/search.store'

import { Dispatch, Reducer, useReducer } from 'react'

import { JobSearch } from '@types'

import useSearchDispatcher from './useSearchDispatcher'

type ErrorActionKind = 'raiseError' | 'resetError' | 'resetErrors' | 'assign'

type ErrorAction = {
  type: ErrorActionKind
  component?: 'job' | 'location'
  value?: Record<'location' | 'job', boolean>
}

type ErrorReducer = Reducer<Record<'location' | 'job', boolean>, ErrorAction>

const useJobSearch = (): {
  submit: (params?: Partial<JobSearch>) => void
  errors: Record<'job' | 'location', boolean>
  dispatchError: Dispatch<ErrorAction>
  setError: (value: boolean, component: 'job' | 'location') => void
} => {
  const searchDispatcher = useSearchDispatcher()

  const nextSearch = useSearchStore((state) => state.nextSearch)

  const [errors, dispatchError] = useReducer<ErrorReducer>(
    (state, action) => {
      switch (action.type) {
        case 'raiseError':
          if (action.component) {
            state[action.component] = true
          }
          return state
        case 'resetError':
          if (action.component) {
            state[action.component] = false
          }
          return state
        case 'resetErrors':
          return Object.fromEntries(Object.entries(state).map(([k]) => [k, false])) as keyof ErrorReducer
        case 'assign':
          if (action.value) {
            return action.value
          }
          return state
        default:
          return state
      }
    },
    { job: false, location: false },
  )

  const submit = (params?: Partial<JobSearch>): void => {
    const nextErrorState = {
      location: !nextSearch.location?.name,
      job: !nextSearch.job?.micro,
    }

    dispatchError({ type: 'assign', value: nextErrorState })

    if (Object.values(nextErrorState).every((x) => !x)) {
      searchDispatcher(params)
    }
  }

  const setError = (value: boolean, component: 'job' | 'location'): void => {
    return dispatchError({ type: value ? 'raiseError' : 'resetError', component })
  }

  return { submit, errors, dispatchError, setError }
}

export default useJobSearch
