export default function queryString(
  obj: Record<string, number | string | string[] | boolean | undefined | null>,
  initialParams?: string | URLSearchParams | Record<string, string>,
): string {
  const params = new URLSearchParams(initialParams)
  Object.entries(obj)
    .filter(([, v]) => v !== null && v !== '' && v !== undefined)
    .forEach(([k, v]) => {
      if (Array.isArray(v)) {
        // URLSearchParams would stringify an array like this:
        // &array_value=1,2,3
        // in order to be compatible with previous implementation (array_value=1&array_value=2&array_value=3)
        // we need to append each array value with .append()
        v.forEach((item) => {
          params.append(k, item)
        })
      } else params.set(k, String(v))
    })
  return params.toString()
}
