/**
 * Get rid of complex (even nested) object fields that have the specified values
 * @params obj, otherTypes
 * @returns A new object without fields of specified values
 */

export default function removeObjectValues(
  obj: Record<string, unknown>,
  fieldValueToRemove: (string | number | null | undefined | unknown)[],
  options?: { keepEmptyObject: boolean },
): Record<string, unknown> {
  const newObj: Record<string, unknown> = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) {
      const cleanedObj = removeObjectValues(obj[key] as Record<string, unknown>, fieldValueToRemove)
      if (Object.keys(cleanedObj).length || options?.keepEmptyObject) {
        newObj[key] = cleanedObj
      }
    } else if (!fieldValueToRemove.includes(obj[key])) newObj[key] = obj[key]
  })
  return newObj
}
