import { useSearchStore } from '@stores/search.store'
import { useUserStore } from '@stores/user.store'

import { useCallback } from 'react'

import type { SearchPreferences } from '@types'

import { useShallow } from 'zustand/react/shallow'

import useLocale from '@hooks/client/useLocale'
import useHttpRequest from '@hooks/useHttpRequest'
import { removeObjectValues } from '@lib/helpers/client'

import useIsLoggedIn from './useIsLoggedIn'

export default function useUpdatePreferences(): (
  token?: string,
  pref?: Partial<SearchPreferences>,
  isLogin?: boolean,
) => void {
  const { isLoggedIn } = useIsLoggedIn()
  const [searchPreferences, setSearchPreferences] = useSearchStore(
    useShallow((st) => [st.searchPreferences, st.setSearchPreferences]),
  )
  const { put } = useHttpRequest()
  const locale = useLocale()
  const storeToken = useUserStore((st) => st.tokens[`${locale}-sonic-user`])

  return useCallback(
    // We pass the token and the preferences in order to prevent
    // slow async store updates
    (token, pref, isLogin) => {
      if (pref) {
        setSearchPreferences(pref)
      }
      if (isLoggedIn || isLogin) {
        put<Promise<null>>('jobseeker/update/search/preference', {
          // We remove empty strings in order to keep the previous setting
          // on the db.
          body: removeObjectValues(pref || searchPreferences, ['']),
          headers: {
            'AUTH-TOKEN': token || storeToken,
          },
        })
      }
    },
    [isLoggedIn, put, searchPreferences, storeToken, setSearchPreferences],
  )
}
