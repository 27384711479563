import type { JobSearch } from '@types'

import queryString from './query-string'

export default function getSerpQueryString(search: JobSearch, pageIndex?: number): string {
  return queryString({
    query: search.job.micro,
    macro: search.job.macro,
    location_id: search.location.id,
    location_name: search.location.name,
    location_state: search.location.state,
    location_county: search.location.county,
    full_time: search.fullTime,
    part_time: search.partTime,
    no_experience: search.noExperience,
    salary_type: search.salary?.min ? search.salary?.type : undefined,
    salary_min: search.salary?.min || null,
    sort: search.sort,
    sortv: search.sortVariant,
    page: pageIndex ? pageIndex + 1 : search.page,
    remote: search.remote,
    max_radius: search.maxRadius,
    created_after: search.createdAfter,
    source: search.source,
  })
}
