/* eslint-disable import/prefer-default-export */
import type { JobSearch, SearchPreferences } from '@types'

import produce from 'immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { miToKm } from '@lib/helpers/client'

type SearchStore = {
  // Gather the current jobSeeker search. It depends also to the URL path
  search: JobSearch
  // It's a clone of the search state used for update just the field withoud re-render other
  // components as fallback like breadcrumb or mixpanel params.
  nextSearch: JobSearch
  // Data used in order to improve the search result for the user.
  // This data is collected during the user session in different places.
  searchPreferences: SearchPreferences
  setSearchPreferences: (pref: Partial<SearchPreferences>) => void
  setSearch: (sh: JobSearch) => void
  setNextSearch: (sh: JobSearch) => void
  addParamsToSearch: (sh: Partial<JobSearch>) => void
  serpPositionClicked?: number
  setSerpPositionClicked: (pos?: number) => void
  scrollPosition: number
  setScrollPosition: (pos: number) => void
  shouldShowJobPopup: boolean
  setShouldShowJobPopup: () => void
  resetSearch: () => void
}

const emptySearch: JobSearch = {
  job: {
    micro: '',
    macro: '',
  },
  salary: {
    type: 'PER_ANNUM',
    min: 0,
  },
  fullTime: true,
  partTime: true,
  noExperience: false,
  sort: 'FEATURED',
  page: 1,
  remote: false,
  maxRadius: miToKm(25),
  location: {
    id: '',
    name: '',
    type: '',
    displayName: '',
    location: [],
    metropolis: '',
    county: '',
    state: '',
    postcode: '',
    active: true,
  },
  instantJobMatch: false,
}

export const useSearchStore = create<SearchStore>()(
  devtools((set) => ({
    search: emptySearch,
    nextSearch: emptySearch,
    searchPreferences: {
      query: '',
      macro: '',
      location_id: '',
      location_name: '',
    },
    setSearchPreferences: (pref): void =>
      set((state) => ({
        searchPreferences: {
          ...state.searchPreferences,
          ...pref,
        },
      })),
    setSearch: (search): void =>
      set({
        search,
        searchPreferences: {
          query: search.job.micro,
          macro: search.job.macro || '',
          location_id: search.location.id || '',
          location_name: search.location.name || '',
          location_state: search.location.state || '',
          location_county: search.location.county || '',
          location_lat: search.location.location?.[1],
          location_long: search.location.location?.[0],
        },
      }),

    setNextSearch: (nextSearch: JobSearch): void => set({ nextSearch }),
    addParamsToSearch: (sh): void =>
      set(
        produce((draft) => {
          Object.keys(sh).forEach((key) => {
            draft.nextSearch[key] = sh[key as keyof typeof sh]
          })
        }),
      ),
    serpPositionClicked: undefined,
    setSerpPositionClicked: (pos): void => set({ serpPositionClicked: pos }),
    scrollPosition: 0,
    setScrollPosition: (pos): void => set({ scrollPosition: pos }),
    shouldShowJobPopup: true,
    setShouldShowJobPopup: (): void => set({ shouldShowJobPopup: false }),
    resetSearch: (): void => set({ nextSearch: emptySearch, search: emptySearch }),
  })),
)
